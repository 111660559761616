
/***********************************************************
 Vuex Event Store
 --------------------------
 Store to use for detail and sub-pages of an event.
 ************************************************************/
import {defineStore} from 'pinia'
import {EventStatus, EventType, isPublicEventStatus, replaceData} from "@spoferan/spoferan-ts-core";
import type {Event, EventPagePreview} from "@spoferan/spoferan-ts-core";
import {ModelType} from "@spoferan/spoferan-ts-core";

type EventAuthPermissions = {
    is_initialized: boolean, // Whether the permissions were loaded
    is_participant: boolean,
    has_rated: boolean,
    is_following: boolean,
    is_member: boolean,
    access_resultastic: boolean,
    participation_ids: number[]
};

const defaultEventAuthPermissions = {
    is_initialized: false,
    is_participant: false,
    has_rated: false,
    is_following: false,
    is_member: false,
    access_resultastic: false,
    participation_ids: [],
}

export const useEventStore = defineStore('event', {
    state: () => ({
        /**
         * The current event details to show.
         */
        event: {
            restrictions: {}
        } as Event,

        /**
         * The permissions / relations of the currently authenticated user on the event.
         */
        authPermissions: {...defaultEventAuthPermissions} as EventAuthPermissions,

        /**
         * The selected media items in the cart on the media portal / photos page.
         *
         * @var {activePrice: {id: int, cents: int}, id: int, internal_id: int, type: string}}[]
         */
        mediaCart: [] as object[],

        isBookmarkModalVisible: false as boolean,
        selectedBookmarkType: null as 'participation'|'bookmark'|null,
    }),

    getters: {
        isFinished: (state) => {
            const {$date} = useNuxtApp();
            return $date().isSameOrAfter(state.event.end_date) && state.event.status.value !== EventStatus.Canceled
        },

        isPublished: (state) => {
            return [EventStatus.Published, EventStatus.Canceled].indexOf(state.event.status.value) > -1
        },

        isPublic: (state) => {
            return isPublicEventStatus(state.event.status.value)
        },

        isCompetition: (state) => {
            return state.event.type.value === EventType.Event
        },

        isParticipant: (state) => {
            return !!state.authPermissions?.is_participant
        },

        isOrganizer: (state) => {
            return !!state.authPermissions?.access_resultastic
        },

        colorStyle: (state) => {
            const config = useRuntimeConfig();
            return state.event.color ?? config.public.colors.primary;
        }
    },

    actions: {

        /**
         * Replaces the state's event details with the specified data by keeping keys in the state that are not specified in the replace data.
         *
         * @param state
         * @param data
         */
        replaceEventData(data: Event) {
            // We need to replace the full target object at once to trigger a full reactive reload of the corresponding components
            this.event = replaceData({...this.event}, data);
        },

        addToMediaCart(data: object) {
            this.mediaCart.push(data);
            this.refreshMediaCartInStorage();
        },

        removeFromMediaCart(data: object) {
            const index = this.mediaCart.findIndex(item => item.id === data.id);
            if (index > -1) {
                this.mediaCart.splice(index, 1);
                this.refreshMediaCartInStorage();
            }
        },

        resetMediaCart() {
            this.mediaCart = [];
            this.refreshMediaCartInStorage();
        },

        initMediaCartFromStorage() {
            const mediaCart = window.localStorage.getItem(`event.${this.event.id}.media_cart`);
            if (mediaCart) {
                this.mediaCart = JSON.parse(mediaCart);
            } else {
                this.mediaCart = [];
            }
        },

        refreshMediaCartInStorage() {
            window.localStorage.setItem(`event.${this.event.id}.media_cart`, JSON.stringify(this.mediaCart));
        },

        async refreshAuthPermissions() {
            const {$auth, $apiFetch} = useNuxtApp();

            // We know the permissions on client side for guests, so we can skip the request
            if (!$auth.loggedIn) {
                this.authPermissions = {...defaultEventAuthPermissions, is_initialized: true};
                return;
            }

            const route = useRoute();

            const {data} = await $apiFetch('/v1/auth/permissions', {
                params: {
                    model_type: ModelType.Event,
                    model_id: route.params.event, // Use route param to refresh auth permissions asap for page redirects
                }
            });
            this.authPermissions = {...data, is_initialized: true};
        },

        getPage(pageType: number): EventPagePreview|null {
            if (!this.event?.pages?.length) {
                return null;
            }

            return this.event.pages.find((page: EventPagePreview) => page.id === pageType) ?? null;
        },

        hasActivePage(pageType: number): boolean {
            return this.getPage(pageType) !== null;
        },

        buildPageTitle(title: string): string {
            return title === this.event.display_name ? title : `${title} | ${this.event.display_name}`
        },

        openAddParticipationModal() {
            this.selectedBookmarkType = 'participation';
            this.isBookmarkModalVisible = true;
        }
    }

});
